<template>
  <section>
    <h2 class="mt-10 primary--text text-center">Lo sentimos, no tienes los permisos necesarios para visualizar esta página.</h2>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  created() {
    this.setIsLogin(false)
  },
  methods: {
    ...mapMutations(['setIsLogin'])
  }
}
</script>

<style>

</style>